
<template>
    <div>
        <v-card>
            <v-card-subtitle>Lista Proveedor

                <v-btn icon @click="cargar()" color="green" :loading="cargandoDatos" :disabled="cargandoDatos">
                    <v-icon>{{ icons.mdiCached }}</v-icon>
                </v-btn>
            </v-card-subtitle>
            <v-card-text>
                <v-row>
                    <v-col cols="12" offset-md="8" md="4">
                        <v-text-field v-model="search" :append-icon="icons.mdiMagnify" label="Buscar" single-line
                            hide-details dense outlined></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>

            <!-- Table -->
            <v-data-table :headers="headers" :items="Lista" :search="search" :items-per-page="5"
                :loading="cargandoDatos" class="table-kitchen-sink">


                <template #[`item.indActivo`]="{ item }">
                    <div class="d-flex align-center">
                        <v-icon color="success" v-if="item.indActivo == true">{{ icons.mdiCheck }}</v-icon>
                        <v-icon color="success" v-else>{{ icons.mdiCancel }}</v-icon>
                    </div>
                </template>
                <template #[`item.documento`]="{ item }">
                    {{ item.tipoDocumento.abreviatura }}-{{ item.documento }}
                </template>

                <template #[`item.datos`]="{ item }">
                    <small>
                        <v-icon small color="success"> {{ icons.mdiPhoneOutline }}</v-icon>
                        {{ item.telefono }}
                    </small>
                    <br>
                    <small>
                        <v-icon small color="success"> {{ icons.mdiEmail }}</v-icon>
                        {{ item.correo }}
                    </small>
                    <br>
                    <small>
                        <v-icon small color="success"> {{ icons.mdiWeb }}</v-icon>
                        {{ item.paginaWeb }}
                    </small>
                </template>
                <template #[`item.accion`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <a class="pr-2" v-bind="attrs" v-on="on" @click="GetEditar(item)">
                                <v-icon>{{ icons.mdiPencil }}</v-icon>
                            </a>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <a class="pr-2" v-bind="attrs" v-on="on" @click="CambiarIndActivo(item)">
                                <v-icon>{{ item.indActivo ? icons.mdiCancel : icons.mdiCheck }}</v-icon>
                            </a>
                        </template>
                        <span>{{ item.indActivo ? 'Inctivar' : 'Activar' }}</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
  
<script>
import { onBeforeMount, ref, context } from '@vue/composition-api'
import {
    mdiMagnify,
    mdiDeleteOutline,
    mdiStoreEdit,
    mdiPencilOutline,
    mdiAccountCancel,
    mdiAccountEdit,
    mdiAccountCheck,
    mdiCheck,
    mdiCancel,
    mdiStoreEditOutline,
    mdiPencil,
    mdiCached,
    mdiPhoneOutline,
    mdiEmail,
    mdiWeb
} from '@mdi/js'
import config from '@/api/config'
import store from '@/store'
import ProveedorServices from '@/api/servicios/ProveedorServices'

export default {
    setup(props, context) {
        const search = ref('')
        const Lista = ref([])
        const ListaLoad = ref(false)
        const cargandoDatos = ref(false)
        const GetEditar = item => {
            console.log(item)
            context.emit('GetEditar', { ...item })

        }
        onBeforeMount(() => {
            cargar()
        })

        const cargar = () => {
            try {

                ProveedorServices.Consultar(
                    {
                        IndConfiguracionInicial : false
                    }
                ).then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {
                        Lista.value = response.data.datos
                    }
                })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        cargandoDatos.value = false
                    })
            } catch (error) {
                //alert(error)
                cargandoDatos.value = false
            }

        }
        const CambiarIndActivo = (item) => {
            console.log("aqui")
            console.log(item)
            cargandoDatos.value = true
            try {
                ProveedorServices.Actualizar(
                    store.state.user.idUsuario,
                    item.id,
                    item.descripcion,
                    !item.indActivo,
                    item.idCategoria
                ).then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {
                        store.commit('setAlert', {
                            message: response.data.mensaje,
                            type: 'success',
                        })
                        cargar()
                    } else {
                        store.commit('setAlert', {
                            message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                            type: 'warning',
                        })
                    }
                })
                    .catch(error => {
                        console.log(error)
                        store.commit('setAlert', {
                            message: error,
                            type: 'error',
                        })
                        cargandoDatos.value = false
                    })

            } catch (error) {
                store.commit('setAlert', {
                    message: error,
                    type: 'error',
                })
                cargandoDatos.value = false
            }


        }


        return {
            icons: {
                mdiMagnify,
                mdiDeleteOutline,
                mdiPencilOutline,
                mdiAccountCancel,
                mdiAccountEdit,
                mdiAccountCheck,
                mdiCheck,
                mdiCancel,
                mdiStoreEdit,
                mdiStoreEditOutline,
                mdiPencil,
                mdiCached,
                mdiPhoneOutline, 
                mdiEmail,
                mdiWeb
            },
            search,
            headers: [
                { text: 'Id', value: 'id' },
                { text: 'Pais', value: 'pais.abreviatura1' },
                { text: 'Documento', value: 'documento' },
                { text: 'Razon Social', value: 'razonSocial' },
                { text: 'Razon Comercial', value: 'razonComercial' },
                { text: 'Datos', value: 'datos' },  
                { text: 'Activo', value: 'indActivo' },
                { text: 'Accion', value: 'accion', sortable: false },
            ],
            Lista,
            ListaLoad,
            GetEditar,
            cargar,
            config,
            cargandoDatos,
            CambiarIndActivo
        }
    },
}
</script>
  
<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
    .v-data-table-header {
        white-space: nowrap;
    }
}
</style>
  